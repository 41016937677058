/* @import url(https://cdn.talentgarden.com/libraries/fontello/css/fontello.css); */

.App {
  text-align: left;
  overflow: hidden;
}

#page-1-div-1 {
  padding-top: 1rem;
}

#page-2-div-4 {
  padding-bottom: 50px;
}

#page-2-div-3 {
  padding: 110px 0px;
}

#page-2-parent {
  padding-top: 80px;
}

p,
h5 {
  white-space: pre-line;
}

.splide--slide {
  margin: 0 auto !important;
}

.slider-image {
  width: 45%;
}

.slider-description {
  width: 35%;
  margin: 0 auto !important;
}

.splide__arrow svg {
  width: 100% !important;
  height: 100% !important;
}

.tag-arrow {
  background: white !important;
  opacity: 1 !important;
  width: 60px !important;
  height: 60px !important;
}

.tag-arrow--prev svg {
  top: 10px;
  position: absolute;
  right: 10px;
}

.tag-arrow--next svg {
  top: 10px;
  position: absolute;
  left: 10px;
}

.accordion {
  cursor: pointer;
  border-bottom: 2px solid #e6e7e8;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
}

.accordion-description {
  cursor: auto;
  width: 80%;
}
h5.tag-h5 {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
}

.color-black {
  color: #1c1e1e;
}

.color-white {
  color: #ffffff;
}

.color-orange {
  color: #ec7660;
}

.color-light-grey {
  color: rgba(28, 30, 30, 0.7);
}

.bg-orange {
  background-color: #ec7660;
}

.bg-peach {
  background-color: rgba(250, 229, 213, 1);
}

.bg-light-grey {
  background-color: #f5f5f5;
}

.full-height {
  height: 100vh;
}

.v-align-center {
  align-items: center;
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 490px);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  justify-content: center;
}

.parent-min-gap {
  display: grid;
  grid-template-columns: repeat(2, 490px);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  justify-content: center;
}

.parent-1col {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 150px;
  justify-content: center;
}

.main-title {
  font-size: 80px;
  text-align: left;
  font-weight: 600;
  line-height: 80px;
  white-space: pre-line;
  margin: 24px 0;
}

.main-card {
  background-color: #ffffff;
  padding: 80px 110px;
  text-align: center;
  box-shadow: 0px 10px 40px -5px rgba(15, 15, 15, 0.12);
}

.main-description {
  padding-right: 60px;
}

.text-pre-line {
  white-space: pre-line;
}

.main_image {
  height: 490px;
  text-align: center;
}

/* slider */

#page-3-div-1 {
  width: 800px;
  height: 1000px;
  border-top-right-radius: 1080px;
  border-top-left-radius: 1080px;
  margin: 0px auto;
  text-align: center;
  padding-bottom: 100px;
  display: flex;
  align-items: flex-end;
}

.cooming-soon__container {
  transform: rotate(45deg);
  position: absolute;
  right: -92px;
  top: 34px;
  overflow: hidden;

  padding: 0 100px;
  background: rgba(114, 51, 73, 1);
  color: white;
  -webkit-box-shadow: -10px 10px 20px -5px rgb(0 0 0 / 50%);
  box-shadow: -10px 10px 20px -5px rgb(0 0 0 / 50%);
}

.footer {
  background: #333;
  color: white;
}

.footer .details {
  padding: 60px 80px;
  display: grid;
  grid-template-columns: 25% 1fr 1fr;
}

.footer .disclaimer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 30px 80px;
  font-size: 70%;
}

.footer .link {
  font-weight: 700;
}

.footer .social-share ul {
  list-style: none;
}

.footer .social-share ul li {
  display: inline-block;
  padding: 0 10px;
}

.logo-link:hover,
.link:hover,
.footer a:hover {
  opacity: 0.8;
  color: white !important;
  text-decoration: none !important;
}

.link,
.link:visited {
  color: white;
  text-decoration: none !important;
}

.form_container {
  max-width: 60%;
  margin: 0 auto;
}

.tag-input__container {
  width: 100%;
  background-color: transparent !important;
}

.autocapitalize {
  text-transform: capitalize;
}

@media screen and (min-width: 860px) and (max-width: 1100px) {
  .only_desktop {
    display: none;
  }

  #page-1-div-1 {
    padding-top: 4rem;
  }

  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    justify-content: center;
    padding: 0 20px;
  }

  .parent-min-gap {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .main-card {
    padding: 80px 60px;
  }

  .parent-1col .child {
    padding: 0 20px;
  }

  .main_image {
    height: 300px;
    padding: 50px 0;
    text-align: center;
  }

  .accordion-description {
    width: 100%;
  }
  .main-description {
    padding-right: 0px;
  }

  .footer .details {
    padding: 60px 80px;
    display: grid;
    grid-template-columns: 40% 1fr;
  }
}

@media screen and (max-width: 859px) {
  .only_desktop {
    display: none;
  }

  #page-1-div-1 {
    padding-top: 4rem;
  }

  .parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    justify-content: center;
    padding: 0 20px;
  }

  .parent-min-gap {
    grid-template-columns: 1fr;
    align-items: baseline;
    padding: 0px 20px;
  }

  .main-card {
    padding: 80px 40px;
    margin-bottom: 20px;
  }

  .parent-1col .child {
    padding: 0 20px;
  }
  .full-height {
    height: auto;
  }

  .main-title {
    font-size: 56px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0em;
  }

  .main_image {
    height: 100vw;
    padding: 0px 0;
    text-align: center;
  }

  .accordion-description {
    width: 100%;
  }
  .main-description {
    padding-right: 0px;
  }

  #page-2-div-3 {
    padding: 40px 0px;
  }

  #page-2-parent {
    padding-top: 40px !important;
  }

  #page-3-parent {
    padding-top: 60px !important;
  }

  #page-3-div-1 {
    width: 336px;
    height: 583px;
    border-top-right-radius: 1080px;
    border-top-left-radius: 1080px;
    margin: 0px auto;
    text-align: center;
    padding-bottom: 60px;
    display: flex;
    align-items: flex-end;
  }

  #page-3-div-2 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .splide--slide {
    margin: 0 auto !important;
  }

  .slider-image {
    width: 60%;
  }

  .slider-description {
    width: 85%;
    margin: 0 auto !important;
  }

  .splide__arrow svg {
    width: 100% !important;
    height: 100% !important;
  }

  .tag-arrow {
    background: white !important;
    opacity: 1 !important;
    width: 40px !important;
    height: 40px !important;
  }

  .splide__arrow--prev {
    left: 0 !important;
  }

  .splide__arrow--next {
    right: 0 !important;
  }

  .tag-arrow--prev svg {
    top: 8px;
    position: absolute;
    right: 8px;
  }

  .tag-arrow--next svg {
    top: 8px;
    position: absolute;
    left: 8px;
  }

  .footer .details {
    padding: 60px 80px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }

  .footer .social-share {
    text-align: center;
  }

  .footer .social-share ul {
    padding-inline-start: 0px;
  }

  .form_container {
    max-width: 95%;
    margin: 0 auto;
  }

  .form-field {
    padding: 10px 0 !important;
  }

  .form-fullname {
    flex-direction: column !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-fullname {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-email {
  display: flex;
  justify-content: center;
}

.form-field {
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
